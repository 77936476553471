import { graphql } from 'gatsby';
import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FormatColorFillTwoToneIcon from '@mui/icons-material/FormatColorFillTwoTone';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';

import Moment from 'react-moment';

import Layout from '../../pages/admin/Layout';

import {
    Button, ButtonBase, Divider, IconButton,
    TextField, Tooltip
} from '@mui/material';
import { SketchPicker } from 'react-color';

import { siteMetadata } from '../../../gatsby-config';
import { setUpdatingData } from '../../redux/admin/adminSlice';
import DataService from '../../services/dataService';

import { useDispatch } from 'react-redux';

const Tag = ({ tag, handleUpdateTags, themeColors, handleRemoveTag, handleUpdateTag }) => {

    const dispatch = useDispatch();

    const [openColorSelect, setOpenColorSelect] = React.useState(false);
    const [openEditOverlay, setOpenEditOverlay] = React.useState(false);
    const [selectedColor, setSelectedColor] = React.useState(tag.color);

    const [titleFormFieldValue, setTitleFormFieldValue] = React.useState('');
    const [editTagNameValue, setEditTagNameValue] = React.useState(tag.title);
    const [createTagButtonDisabled, setCreateTagButtonDisabled] = React.useState();
    const [editTagButtonDisabled, setEditTagButtonDisabled] = React.useState();

    const updateTagColor = (hexColor) => {

        dispatch(setUpdatingData({ updatingData: true }));

        DataService.updateRecord(tag.id, {
            color: hexColor
        }).then(updatedTag => {

            handleUpdateTags(tag, updatedTag);
        })

        dispatch(setUpdatingData({ updatingData: false }));
    }

    const updateTag = () => {

        dispatch(setUpdatingData({ updatingData: true }));

        DataService.updateRecord(tag.id, {
            title: editTagNameValue
        }).then( updatedTag => {
            handleUpdateTags(tag, updatedTag);
            dispatch(setUpdatingData({ updatingData: false }));
        })

    }

    const deleteTag = () => {

        const confirm = window.confirm("Are you sure you want to delete this tag?")

        dispatch(setUpdatingData({ updatingData: true }));

        if (confirm) {
            DataService.deleteRecord(tag.id)
                .then( res => {
                    handleRemoveTag(tag)
                    setOpenEditOverlay(false);
                    dispatch(setUpdatingData({ updatingData: false }));
                })   
        }
    }

    return (
        <div style={{ marginRight: 30, marginTop: 30 }} >
            <div 
                className="shadow-lg"
                style={{ 
                    display: 'flex', 
                    backgroundColor: 'whitesmoke',
                    padding: '2rem',
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderStyle: 'solid',
                    borderColor: 'gray',
                    borderWidth: 1,
                    borderRadius: 4,
                }}>
                <LocalOfferOutlinedIcon 
                    style={{
                        transform: 'rotate(315deg)'
                    }}
                    sx={{ fontSize: 150, color: tag.color }}
                />
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center' 
                }}>
                    <div style={{ display: 'flex' }}>
                        <p 
                            style={{ 
                                fontFamily: 'monospace',
                                fontSize: 25,
                                fontWeight: 'bold',
                                color: '#45425a'
                            }}
                        >
                            {tag.title}
                        </p>
                        <Tooltip 
                            title={"Change Color"} 
                            placement='right'
                            className="hi"
                            style={{
                                width: 50,
                                height: 50,
                                top: '50%',
                                transform: 'translate(0, -50%)',
                                marginLeft: 'auto'
                            }}
                        >
                            <IconButton onClick={() => {
                                setOpenColorSelect(true)
                            }}>
                                <FormatColorFillTwoToneIcon 
                                    style={{ 
                                        fontSize: 20, 
                                        color: tag.color
                                    }} 
                                />
                            </IconButton>
                        </Tooltip>
                        <Tooltip 
                            title={"Edit"} 
                            placement='right'
                            className="hi"
                            style={{
                                width: 50,
                                height: 50,
                                top: '50%',
                                transform: 'translate(0, -50%)',
                                marginLeft: 'auto'
                            }}
                        >
                            <IconButton onClick={() => {
                                setOpenEditOverlay(true);

                            }}>
                                <EditOutlinedIcon
                                    style={{ 
                                        fontSize: 20, 
                                        // color: tag.color
                                    }} 
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <p
                        style={{
                            fontFamily: 'monospace',
                            marginTop: 0
                        }}
                    >
                        Linked with {tag.posts.length} posts. 
                    </p>
                    <p 
                        style={{ 
                            fontFamily: 'monospace',
                            marginTop: 0
                        }}
                    >
                        Created On <Moment format="D MMM YYYY" >{tag.created}</Moment>
                    </p>

                </div>
            </div>

            {
                openColorSelect && (
                    <div 
                        draggable={true}
                        id="overlayBackground"
                        style={{ 
                            height: '100%', 
                            width: '100%', 
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            // backgroundColor: 'red',
                            position: 'fixed',
                            zIndex: 2,
                            top: 0,
                            left: 0
                        }}
                        onClick={(e) => {
                            const background = document.getElementById('overlayBackground');
                            if (background === e.target) {
                                setOpenColorSelect(false);
                                // dispatch(setSectionsDragDisabled({ sectionsDragDisabled: false }));
                            }
                        }}
                        onDragStart={event => event.preventDefault() }
                    >
                        <div 
                            className="flex flex-col justify-evenly"
                            style={{ 
                                backgroundColor: 'whitesmoke',
                                width: '50%',
                                height: '55%',
                                minWidth: '40rem',
                                borderRadius: 5,
                                borderStyle: 'solid',
                                borderWidth: 1,
                                borderColor: 'grey',
                                position: 'relative',
                                zIndex: 3
                            }}
                            onClick={() => {
                                setOpenColorSelect(true);
                            }}
                        >
                            <h4 className="text-center" style={{ fontFamily: 'monospace', fontSize: 30 }}>Change Color</h4>

                            <Divider className='w-3/4 mx-auto' />

                            <div className="flex justify-evenly content-center">
                                <div>
                                    <SketchPicker 
                                        color={selectedColor}
                                        onChangeComplete={(colour, event) => {
                                            setSelectedColor(colour.hex);
                                            updateTagColor(colour.hex);
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col justify-evenly items-center">
                                    <p className="text-center">
                                        Theme Colors
                                    </p>
                                    {Object.keys(themeColors).map( color => {

                                        console.log(color)

                                        return (
                                        <Tooltip 
                                            title={color}
                                            placement='left-start'
                                            className="hi"
                                        >
                                            <ButtonBase onClick={() => {
                                                setSelectedColour(themeColors[color]);
                                                updateSettings(themeColors[color], `${pointerString.split('.')[0]}`);
                                                // dispatch(setSectionsDragDisabled({ sectionsDragDisabled: true }));
                                            }}>
                                                <div
                                                    className="w-10 h-10 rounded-full mt-4 border-black border-2"
                                                    style={{
                                                        backgroundColor: themeColors[color]
                                                    }}
                                                />
                                            </ButtonBase>
                                        </Tooltip>
                                    )})}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                openEditOverlay && (
                    <div
                        style={{
                            position: 'fixed',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            top: 0,
                            left: 0,
                            zIndex: 2
                        }}
                    >
                        <div 
                            style={{ 
                                backgroundColor: 'whitesmoke',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                alignContent: 'center',
                                width: '60%',
                                height: '25%',
                                alignItems: 'center',
                                borderRadius: 5,
                                borderStyle: 'solid',
                                borderWidth: 1,
                                borderColor: 'grey',
                                position: 'relative',
                                zIndex: 3,
                                top: '50%',
                                margin: 'auto',
                                transform: 'translate(0, -50%)',
                                padding: '2rem'
                            }}
                        >
                            <h2 
                                style={{ 
                                    fontFamily: 'monospace',
                                    color: '#45425a'
                                }}
                                className="text-xl font-semibold p-3"
                            >
                                Create Tag
                            </h2>
                            <TextField 
                                label="Tag Title" 
                                varient="filled"
                                defaultValue={tag.title}
                                // value={titleFormFieldValue}
                                onChange={(e) => {
                                    
                                    setEditTagButtonDisabled(false);
                                    if (e.target.value.length < 1) setEditTagButtonDisabled(true)
                                    
                                    setEditTagNameValue(e.target.value)
                                }}
                                style={{
                                    width: '90%',
                                    marginBottom: '2rem'
                                }}
                            /> 
                            <div 
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-start'
                                }}
                            >
                                <Button 
                                    varient="contained" 
                                    disabled={editTagButtonDisabled}
                                    style={{ 
                                        backgroundColor: "darkred",
                                        color: 'whitesmoke',
                                        marginBottom: '2rem',
                                        marginLeft: '5%',
                                        opacity: createTagButtonDisabled ? 0.2 : 1 
                                    }}
                                    onClick={() => deleteTag() }
                                >
                                    Remove Tag
                                </Button>
                                <Button 
                                    varient="contained" 
                                    disabled={createTagButtonDisabled}
                                    style={{ 
                                        backgroundColor: "lightblue",
                                        color: 'whitesmoke',
                                        marginBottom: '2rem',
                                        marginRight: 'auto',
                                        marginLeft: '5%',
                                        opacity: createTagButtonDisabled ? 0.2 : 1 
                                    }}
                                    onClick={() => {
                                        setOpenEditOverlay(false)
                                        updateTag() 
                                    }}
                                >
                                    Update Tag
                                </Button>
                            </div>
                        </div>
                        <IconButton 
                            style={{ 
                                position: 'fixed', 
                                right: 20, 
                                top: 20, 
                                zIndex: 5
                            }} 
                            onClick={() => setOpenEditOverlay(false) } 
                            title="close"
                        >
                            <CloseIcon style={{ 
                                color: 'whitesmoke'
                            }} />
                        </IconButton>
                    </div>
                )
            }
        </div>
    )
}

const Tags = ({ data, pageContext }) => {

    const dispatch = useDispatch();

    const [tags, setTags] = React.useState([]);
    const [orders, setOrders] = React.useState('created');
    const [showCreateForm, setShowCreateForm] = React.useState(false);

    const [siteId] = React.useState(data.datoCmsWebsite.id)

    const [titleFormFieldValue, setTitleFormFieldValue] = React.useState('');
    const [createTagButtonDisabled, setCreateTagButtonDisabled] = React.useState(true);

    React.useEffect(() => {
        setTags(data.allDatoCmsTag.nodes);
    }, []);

    const handleUpdateTags = (tag, updatedTag) => {
        let updatedTagIndex = tags.indexOf(tag);

        let newTags = tags;
        newTags[updatedTagIndex] = updatedTag;
        setTags([...newTags]);
    };

    const handleUpdateTag = () => {

    }

    const handleRemoveTag = (tag) => {

        const tagIndex = tags.indexOf(tag);
        let newTags = tags;
        newTags.splice(tagIndex, 1);
        setTags([...newTags]);
    }

    const createTag = () => {

        dispatch(setUpdatingData({ updatingData: true }))

        DataService.create({
            title: titleFormFieldValue,
            color: 'black',
            created: new Date().toISOString(),
            site: siteId.split('-')[1],
            item_type: { type: 'item_type', id: siteMetadata.datoCmsModelIds.Tag.toString() }
        }).then( res => {
            DataService.getAllRecords(siteMetadata.datoCmsModelIds.Tag, siteId.split('-')[1])
                .then( tags => {
                    console.log(tags);
                    setTags(tags);
                    setShowCreateForm(false);
                    dispatch(setUpdatingData({ updatingData: false }));
                })
        })
    }

    return (
        <Layout>
            <div>
                <h1 className="text-2xl font-bold text-center" style={{ fontFamily: 'monospace', color: '#45425a' }}>Tags</h1>

                <Divider className="mt-1" />
                {/* {JSON.stringify(JSON.parse(pageContext.siteSettings).colors)} */}
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {
                        tags.map( (tag, index) => (
                            <Tag
                                key={index}
                                tag={tag} 
                                themeColors={JSON.parse(data.datoCmsWebsite.settings).colors}
                                handleUpdateTags={handleUpdateTags}
                                handleRemoveTag={handleRemoveTag}
                                handleUpdateTag={handleUpdateTag}
                            />
                        ))
                    }
                </div>
            </div>
            <Tooltip 
                title={"Add"} 
                placement='left-start'
                className="shadow-md"
                style={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    backgroundColor: '#45425a',
                    width: 100,
                    height: 100,
                }}
            >
                <IconButton 
                    onClick={() => setShowCreateForm(true) }
                >
                    <AddIcon style={{ textAlign: 'right', fontSize: 50, color: 'white' }} />
                </IconButton>
            </Tooltip>

            {
                showCreateForm && (
                    <div
                        style={{
                            position: 'fixed',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            top: 0,
                            left: 0
                        }}
                    >
                        <div 
                            style={{ 
                                backgroundColor: 'whitesmoke',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                alignContent: 'center',
                                width: '60%',
                                height: '25%',
                                alignItems: 'center',
                                borderRadius: 5,
                                borderStyle: 'solid',
                                borderWidth: 1,
                                borderColor: 'grey',
                                position: 'relative',
                                zIndex: 3,
                                top: '50%',
                                margin: 'auto',
                                transform: 'translate(0, -50%)'
                            }}
                        >
                            <h2 
                                style={{ 
                                    fontFamily: 'monospace'
                                }}
                                className="text-xl font-semibold p-4"
                            >
                                Create Tag
                            </h2>
                            <Divider />
                            <TextField 
                                label="Tag Title" 
                                varient="filled" 
                                value={titleFormFieldValue}
                                onChange={(e) => {
                                    
                                    if (createTagButtonDisabled) setCreateTagButtonDisabled(false);
                                    if (e.target.value.length < 1) setCreateTagButtonDisabled(true)
                                    
                                    setTitleFormFieldValue(e.target.value);
                                }}
                                style={{
                                    width: '90%',
                                    marginBottom: '2rem'
                                }}
                            /> 
                            <Button 
                                varient="contained" 
                                disabled={createTagButtonDisabled}
                                style={{ 
                                    backgroundColor: "#45425a",
                                    color: 'whitesmoke',
                                    marginBottom: '2rem',
                                    marginRight: 'auto',
                                    marginLeft: '5%',
                                    opacity: createTagButtonDisabled ? 0.2 : 1 
                                }}
                                onClick={createTag}
                            >
                                Create Tag
                            </Button>
                        </div>
                        <IconButton 
                            style={{ 
                                position: 'fixed', 
                                right: 20, 
                                top: 20, 
                                zIndex: 5
                            }} 
                            onClick={() => setShowCreateForm(false) } 
                            title="close"
                        >
                            <CloseIcon style={{ 
                                color: 'whitesmoke'
                            }} />
                        </IconButton>
                    </div>
                )
            }
        </Layout>
    )
}


export const query = graphql`
    query TagQuery($sitename: String) {
        allDatoCmsTag(filter: {site: {name: {eq: $sitename}}}) {
            nodes {
                title
                id
                created
                color
                site {
                    id
                }
                posts {
                    id
                    title
                }
            }
        }

        datoCmsWebsite(name: {eq: $sitename}) {
            id
            settings
        }
    }
`;

export default Tags;
